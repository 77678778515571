<template>
  <div class="col-12" v-show="listingOperational.show">
    <div class="form--group row align-center mb-4 operational--hour-field">
      <div class="col-4 d-flex align-center py-0">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            @click="toggleOpen"
            :id="'operationalDay' + idx"
            :value="listingOperational.day"
            :checked="listingOperational.showTime"
            name="operationalDay[]"
          />
          <label class="custom-control-label" :for="'operationalDay' + idx">{{
            $t('date.day.' + listingOperational.day)
          }}</label>
        </div>
      </div>
      <div class="col-4 py-0">
        <multiselect
          :value="selectedOpenTime"
          :disabled="!listingOperational.showTime"
          name="openTime"
          @input="openTimeChanged"
          :options="openOptions"
          :searchable="false"
          :close-on-select="true"
          track-by="timeLabel"
          :show-labels="true"
          :custom-label="timeLabel"
          :allow-empty="false"
          placeholder="Pilih Jam Buka"
        ></multiselect>
      </div>
      <div class="col-4 py-0">
        <multiselect
          :value="selectedCloseTime"
          :disabled="!listingOperational.showTime"
          name="closeTime"
          @input="closeTimeChanged"
          :options="closeOptions"
          :searchable="false"
          :close-on-select="true"
          track-by="timeLabel"
          :show-labels="true"
          :custom-label="timeLabel"
          :allow-empty="false"
          placeholder="Pilih Jam Tutup"
        ></multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Multiselect from 'vue-multiselect';

require('vue-multiselect/dist/vue-multiselect.min.css');
export default {
  name: 'operational-hour-field',
  props: ['day', 'idx'],
  components: {
    Multiselect,
  },
  computed: {
    ...mapGetters({}),
    selectedOpenTime() {
      let self = this;
      const result = self.$store.getters['v2/listingForm/sect2/availability/getSelectedOpenTime'](
        self.day,
      );
      return result;
    },
    selectedCloseTime() {
      let self = this;
      const result = self.$store.getters['v2/listingForm/sect2/availability/getSelectedCloseTime'](
        self.day,
      );
      return result;
    },
    openOptions() {
      let self = this;
      const result = self.$store.getters['v2/listingForm/sect2/availability/getOpenOptions'](
        self.day,
      );
      return JSON.parse(JSON.stringify(result));
    },
    closeOptions() {
      let self = this;
      const result = self.$store.getters['v2/listingForm/sect2/availability/getCloseOptions'](
        self.day,
      );
      return JSON.parse(JSON.stringify(result));
    },
    listingOperational() {
      return this.$store.getters['v2/listingForm/sect2/availability/getListingOperational'](
        this.day,
      );
    },
  },
  methods: {
    timeLabel(listingOperational) {
      return listingOperational.timeLabel;
    },

    toggleOpen() {
      this.$store.commit(
        'v2/listingForm/sect2/availability/TOGGLE_LISTINGOPERATIONAL_OPEN',
        this.day,
      );
    },
    openTimeChanged(val) {
      this.$store.dispatch('v2/listingForm/sect2/availability/setSelectedOpenTime', {
        newSelected: val.time,
        day: this.day,
      });
    },
    closeTimeChanged(val) {
      this.$store.dispatch('v2/listingForm/sect2/availability/setSelectedCloseTime', {
        newSelected: val.time,
        day: this.day,
      });
    },
  },
};
</script>

<style lang="scss">
.operational--hour-field {
  .multiselect--disabled .multiselect__current,
  .multiselect--disabled .multiselect__select {
    background-color: transparent;
  }
}
</style>
